<script>
	export let playing;
	export let loading;
	export let christmas;
	export let togglePlay = () => {};
</script>

<button class={`${playing && 'playing'} ${loading && 'loading'} `} on:click={togglePlay}>
	<div class="content">
		<svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve">
			{#if playing}
				<path d="M162.642 148.337h56.034v215.317h-56.034v-215.316z" fill="#000000" />
				<path d="M293.356 148.337h56.002v215.317h-56.002v-215.316z" fill="#000000" />
			{:else}
				<path d="M156.4,102.9L421.6,256L156.4,409.1L156.4,102.9z" />
			{/if}
		</svg>

		{#if christmas}
			<img class="santaHat" src="/hat.png" alt="" loading="lazy" />
		{/if}
	</div>
</button>

<style>
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		margin: 1rem;
		cursor: pointer;
		border: none;
		background-color: transparent;
	}

	button:focus {
		outline: none;
	}

	.santaHat {
		display: block;
		position: absolute;
		top: -15px;
		right: -25px;
		width: 50px;
	}

	.content {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		padding: 0;
		width: 250px;
		height: 90px;
		border: none;
		border-radius: 15px;
		background-color: #ddd;
		font-weight: bold;
		font-size: 1.6rem;
	}

	@media (prefers-color-scheme: dark) {
		.content {
			background-color: #888;
		}
	}

	@media (max-height: 590px) {
		.content {
			height: 65px;
		}
	}

	.content svg {
		display: block;
		z-index: 1;
		width: 50px;
		height: 50px;
	}

	@keyframes loading {
		0% {
			background-position: 0% 50%;
		}
		100% {
			background-position: 100% 50%;
		}
	}

	.content:before {
		content: '';
		position: absolute;
		inset: 0;
		z-index: 0;
		opacity: 0;
		transition: opacity 0.5s linear;
		transition-delay: 0.4s;
		border-radius: 15px;
		background-image: repeating-linear-gradient(
			50deg,
			transparent,
			transparent 0.75rem,
			#999 0.75rem,
			#999 1.5rem
		);
		background-size: 200% 200%;
		animation: loading 5s infinite linear;
	}

	@media (prefers-reduced-motion: no-preference) {
		.loading .content:before {
			opacity: 1;
		}
	}
</style>
